import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import MovingElement from "@elements/MovingElement"
import { motion } from "framer-motion"

const Section = styled.section`
  ${tw`relative z-20 flex flex-col h-auto md:min-h-80vw lg:min-h-70vw`}
`

const ContentWrap = styled.div`
  ${tw`relative z-10 flex flex-col items-center col-span-12 mt-auto mb-auto font-black text-black font-ubuntu pt-72 md:pt-24 px-offset md:px-offsetMd lg:px-16 xl:px-24 2xl:px-32 sm:w-3/4 md:w-1/2 2xl:w-3/5`}
`
const ImageWrap = styled.div`
  ${tw`absolute top-0 left-0 right-0 flex flex-col max-h-full col-span-12 mx-auto -bottom-40 md:col-span-6 mt-c20 md:mt-c48 2xl:mt-c60 md:mb-c96 sm:w-3/5 sm:left-auto`}
`
const Description = styled.div`
  ${tw`relative z-10 flex flex-col items-center pt-6 pb-20 mr-auto font-normal md:pb-0 md:col-start-2 md:text-center lg:px-8 xl:px-12 md:w-3/4`}

  a {
    ${tw`mt-6`}
  }
`
const Bracket = styled.span`
  ${tw`absolute right-0 z-0 hidden font-bold md:block font-firacode md:-top-6 xl:-top-10 md:text-400/400 xl:text-500/500 text-yellow`}
  writing-mode: vertical-lr;
`

const WpAcfHeaderRefBlock = ({ moduleData, featuredImage }) => {
  const blockData = moduleData.acfRefHeaderBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes?.anchor || ""
  const image = getImage(featuredImage.node.localFile)

  return (
    <Section id={sectionId}>
      <ContentWrap>
        <h1 className="relative font-bold headline-refheader font-ubuntu text-40 xl:text-64 2xl:text-80">
          <span
            className="relative text-right"
            data-start-symbol={blockData.startSymbol}
            data-end-symbol={blockData.endSymbol}
          >
            {parse(blockData.headline)}
          </span>
        </h1>
        <Description className="copy md:mt-8">
          {parse(blockData.description)}

          <div className="relative floating-symbol pt-c25">
            <MovingElement
              speed={0.05}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="absolute left-0 right-0 mx-auto transition-all duration-300 ease-linear transform top-c40"
            >
              <span
                style={{
                  writingMode: "vertical-lr",
                  lineHeight: "1.6",
                }}
                className="absolute left-0 right-0 mx-auto text-6xl font-black text-center transition-all duration-300 ease-linear transform scroll-element font-firacode whitespace-nowrap text-blue top-c80"
              >
                {parse(`->-`)}
              </span>
            </MovingElement>
          </div>
        </Description>
      </ContentWrap>
      <ImageWrap>
        <motion.div className="flex justify-center w-full h-full max-h-full">
          <GatsbyImage
            objectFit=""
            className="w-full mt-24 absolute top-0 left-0 right-0 md:relative shadow-big mx-auto max-w-[250px] max-h-[250px] sm:max-h-full sm:max-w-full"
            image={image}
            alt={featuredImage.node?.altText}
          />
        </motion.div>
      </ImageWrap>
      <Bracket>{`}`}</Bracket>
    </Section>
  )
}

WpAcfHeaderRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfHeaderRefBlock
